import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig";
import Button from "react-bootstrap/Button";
import { IPublicClientApplication } from "@azure/msal-browser";
import styles from './SignInButton.module.css';

function handleLogin(instance: IPublicClientApplication) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    var ButtonClasses = styles.FloatRight + " " + styles.ButtonMargin + " " + styles.ButtonColor + " ml-auto FloatRight";

    return (
        <Button className={ ButtonClasses } onClick={() => handleLogin(instance)}>Sign In With Microsoft</Button>
    );
}