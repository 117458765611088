import React from 'react';
import ReactDOM from 'react-dom';
import './common/index.css';
import App from './components/App/App';
import reportWebVitals from './config/reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AccountInfo, EventMessage, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './config/authConfig';
import Favicon from 'react-favicon';
import faviconURL from './assets/favicon.png';

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && ((event.payload as any).account as AccountInfo)) {
    const account = (event.payload as any).account as AccountInfo;
    msalInstance.setActiveAccount(account);
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    console.log(JSON.stringify(event));
  }
});

ReactDOM.render(
  <React.StrictMode>
     <MsalProvider instance={msalInstance}>
       <Favicon url={faviconURL} />
        <App instance={msalInstance} />
      </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
