import React from 'react';
import { DropdownProps, Form, Icon, InputOnChangeData } from 'semantic-ui-react';
import { userInputStateOptions } from '../QueryBuilder/QueryBuilderOptions';
import { ISearchStreetProps } from './ISearchStreetProps';
import { ISearchStreetState } from './ISearchStreetState';
import styles from './SearchStreet.module.css';


export default class SearchStreet extends React.Component<ISearchStreetProps, ISearchStreetState> { 

  constructor(props: any){
    super(props);
    this.state = {
      userInputStreetAddress: '',
      userInputSuburb: '',
      userInputState: '',
      userInputPostCode: '',
      userInputDateCreatedFrom: '',
      userInputDateCreatedTo: '',
      queryData: {'Entity' : 'Street'},
      searchQueryData: {}
    }
  }

  private updateQueryData = async() => {
    let queryDataJson = {
      'Entity': 'Street',
      'StreetAddress': this.state.userInputStreetAddress,
      'Suburb': this.state.userInputSuburb,
      'State': this.state.userInputState,
      'PostCode': this.state.userInputPostCode,
      'DateCreatedFrom': this.state.userInputDateCreatedFrom,
      'DateCreatedTo': this.state.userInputDateCreatedTo
    }
    await this.setState( {queryData: queryDataJson} );
  }

  private handleFormSubmit = async() => {
    await this.setState({ searchQueryData: this.state.queryData });
    this.props.searchButtonClicked( this.state.queryData );
  }

  private handleInputChanges = async (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    let stateName = "userInput" + data.name;
    await this.setState( { [stateName as any] : data.value as string } as Pick<ISearchStreetState, keyof ISearchStreetState> );
    await this.updateQueryData();
  }

  private handleDropdownChanges = async (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    await this.setState( {userInputState : data.value as string} );
    await this.updateQueryData();
  }

  private handleClearDate = async(componentName: string) => {
    let stateName = 'userInput' + componentName;
    await this.setState( { [stateName as any] : '' } as Pick<ISearchStreetState, keyof ISearchStreetState> ) ;
    await this.updateQueryData();
  }

  public render(): React.ReactElement<ISearchStreetProps>
  {
    return (
      <Form onSubmit= { this.handleFormSubmit }>
        <Form.Group widths='equal'>
          <Form.Input fluid name='StreetAddress' label='Street Address' placeholder='Street Address'
            value= {this.state.userInputStreetAddress} 
            className={ styles.DarkerInput } onChange={ (e, data) => this.handleInputChanges(e, data)}/>
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input fluid name='Suburb' label='Suburb' placeholder='Suburb'
            value= {this.state.userInputSuburb} 
            className={ styles.DarkerInput } onChange={ (e, data) => this.handleInputChanges(e, data)}/>
          <Form.Dropdown fluid name='State' label='State' placeholder='State' clearable options= { userInputStateOptions } selection 
            value= {this.state.userInputState} onChange={ (e, data) =>this.handleDropdownChanges(e, data)}
            className={ styles.DarkerDropdown } />
          <Form.Input fluid type='number' name='PostCode' label='Post Code' placeholder='Post Code'
            value= {this.state.userInputPostCode} min='1000' max='9999'
            className={ styles.DarkerInput } onChange={ (e, data) => this.handleInputChanges(e, data)}/>
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input fluid name='DateCreatedFrom' type='date' className={ styles.DarkerInput + " " + styles.DarkerInputDate + " " + styles.DateInput } label='Date Created From' placeholder='Date Created From'
              value= {this.state.userInputDateCreatedFrom} min='1970-01-01'
              onChange={ (e, data) => {this.handleInputChanges(e, data)}} 
              icon={ <Icon link name='close' onClick={ () => {this.handleClearDate('DateCreatedFrom')} } /> } />
          <Form.Input fluid name='DateCreatedTo' type='date' className={ styles.DarkerInput + " " + styles.DarkerInputDate + " " + styles.DateInput } label='Date Created To' placeholder='Date Created To'
            value= {this.state.userInputDateCreatedTo} min='1970-01-01'
            onChange={ (e, data) => {this.handleInputChanges(e, data)}} 
            icon={ <Icon link name='close' onClick={ () => {this.handleClearDate('DateCreatedTo')} } /> } />
        </Form.Group>
        <Form.Group widths='equal' className={ styles.MinHeightGroup }>
          <Form.Button color='red' className={ styles.SearchButton }>Search</Form.Button>
        </Form.Group>
      </Form>
    );
  }

}