export const CallerEntitySearchResultColumns = [
    {
        name: 'Caller ID',
        selector: (row: { [x: string]: any; }) => row['CallerID'],
        sortable: true,
        omit: false
    },
    {
        name: 'Email Address',
        selector: (row: { [x: string]: any; }) => row['EmailAddress'],
        sortable: true,
        omit: false
    },
    {
        name: 'First Name',
        selector: (row: { [x: string]: any; }) => row['FirstName'],
        sortable: true,
        omit: false
    },
    {
        name: 'Last Name',
        selector: (row: { [x: string]: any; }) => row['LastName'],
        sortable: true,
        omit: false
    },
    {
        name: 'Mobile Number',
        selector: (row: { [x: string]: any; }) => row['MobileNumber'],
        sortable: true,
        omit: false
    },
    {
        name: 'Post Code',
        selector: (row: { [x: string]: any; }) => row['PostCode'],
        sortable: true,
        omit: false
    },
    {
        name: 'Username',
        selector: (row: { [x: string]: any; }) => row['UserName'],
        sortable: true,
        omit: false
    },
    {
        name: 'Active Y/N',
        selector: (row: { [x: string]: any; }) => row['ActiveYN'],
        sortable: true,
        omit: false
    },
];

export const UtilityEntitySearchResultColumns = [
    {
        name: 'Utility ID',
        selector: (row: { [x: string]: any; }) => row['UtilityID'],
        sortable: true,
        omit: false
    },
    {
        name: 'Utility Name',
        selector: (row: { [x: string]: any; }) => row['UtilityName'],
        sortable: true,
        omit: false
    },
    {
        name: 'Job Number',
        selector: (row: { [x: string]: any; }) => row['JobNumber'],
        sortable: true,
        omit: false
    },
    {
        name: 'Date Created',
        selector: (row: { [x: string]: any; }) => row['DateCreated'],
        sortable: true,
        omit: false
    },
    {
        name: 'Caller ID',
        selector: (row: { [x: string]: any; }) => row['CallerID'],
        sortable: true,
        omit: false
    },
    {
        name: 'Authority Name',
        selector: (row: { [x: string]: any; }) => row['AuthorityName'],
        sortable: true,
        omit: false
    },
    {
        name: 'Activity',
        selector: (row: { [x: string]: any; }) => row['Activity'],
        sortable: true,
        omit: false
    },
    {
        name: 'Active Y/N',
        selector: (row: { [x: string]: any; }) => row['ActiveYN'],
        sortable: true,
        omit: false
    },
];

export const StreetEntitySearchResultColumns = [
    {
        name: 'Caller ID',
        selector: (row: { [x: string]: any; }) => row['CallerID'],
        sortable: true,
        omit: false
    },
    {
        name: 'Job Number',
        selector: (row: { [x: string]: any; }) => row['JobNumber'],
        sortable: true,
        omit: false
    },
    {
        name: 'Date Created',
        selector: (row: { [x: string]: any; }) => row['DateCreated'],
        sortable: true,
        omit: false
    },
    {
        name: 'Street Address',
        selector: (row: { [x: string]: any; }) => row['StreetAddress'],
        sortable: true,
        omit: false
    },
    {
        name: 'Suburb',
        selector: (row: { [x: string]: any; }) => row['Suburb'],
        sortable: true,
        omit: false
    },
    {
        name: 'State',
        selector: (row: { [x: string]: any; }) => row['State'],
        sortable: true,
        omit: false
    },
    {
        name: 'Post Code',
        selector: (row: { [x: string]: any; }) => row['PostCode'],
        sortable: true,
        omit: false
    },
];

export const EnquiryEntitySearchResultColumns = [
    {
        name: 'Caller ID',
        selector: (row: { [x: string]: any; }) => row['CallerID'],
        sortable: true,
        omit: false
    },
    {
        name: 'Job Number',
        selector: (row: { [x: string]: any; }) => row['JobNumber'],
        sortable: true,
        omit: false
    },
    {
        name: 'Job Purpose',
        selector: (row: { [x: string]: any; }) => row['JobPurpose'],
        sortable: true,
        omit: false
    },
    {
        name: 'Authority Name',
        selector: (row: { [x: string]: any; }) => row['AuthorityName'],
        sortable: true,
        omit: false
    },
    {
        name: 'Activity',
        selector: (row: { [x: string]: any; }) => row['Activity'],
        sortable: true,
        omit: false
    },
    {
        name: 'Date Created',
        selector: (row: { [x: string]: any; }) => row['DateCreated'],
        sortable: true,
        omit: false
    },
    {
        name: 'Active Y/N',
        selector: (row: { [x: string]: any; }) => row['ActiveYN'],
        sortable: true,
        omit: false
    }
];