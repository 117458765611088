// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchExpandableRow_SearchExpandableRow__qqYXv {\n    padding: 10px 10px 20px 10px;\n    display: inline-flex;\n    flex-wrap: wrap;\n    background-color: #fff6f6;\n}\n\n.SearchExpandableRow_PropertyDiv__23y3M {\n    width: 33%;\n}\n\n.SearchExpandableRow_PropertyDivFullWidth__1NaZ4 {\n    padding-top: 5px;\n    width: 100%;\n}\n\n.SearchExpandableRow_PropertyName__2qTjN {\n    padding: 0 5px;\n    font-size: 14px;\n}\n\n.SearchExpandableRow_PropertyValue__3csrN {\n    padding: 0 5px;\n    font-size: 14px;\n}", "",{"version":3,"sources":["webpack://src/components/SearchExpandableRow/SearchExpandableRow.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,oBAAoB;IACpB,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB","sourcesContent":[".SearchExpandableRow {\n    padding: 10px 10px 20px 10px;\n    display: inline-flex;\n    flex-wrap: wrap;\n    background-color: #fff6f6;\n}\n\n.PropertyDiv {\n    width: 33%;\n}\n\n.PropertyDivFullWidth {\n    padding-top: 5px;\n    width: 100%;\n}\n\n.PropertyName {\n    padding: 0 5px;\n    font-size: 14px;\n}\n\n.PropertyValue {\n    padding: 0 5px;\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SearchExpandableRow": "SearchExpandableRow_SearchExpandableRow__qqYXv",
	"PropertyDiv": "SearchExpandableRow_PropertyDiv__23y3M",
	"PropertyDivFullWidth": "SearchExpandableRow_PropertyDivFullWidth__1NaZ4",
	"PropertyName": "SearchExpandableRow_PropertyName__2qTjN",
	"PropertyValue": "SearchExpandableRow_PropertyValue__3csrN"
};
export default ___CSS_LOADER_EXPORT___;
