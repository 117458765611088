// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignInButton_FloatRight__D2rTW {\n    float: right;\n}\n\n.SignInButton_ButtonMargin__1QC58 {\n    /* margin: 0px 25px; */\n}\n\n.SignInButton_ButtonColor__1iYuL {\n    background-color: #c7242c !important;\n    border-color: #c7242c !important;\n}\n\n@media only screen and (max-width: 767px) {\n    .SignInButton_ButtonMargin__1QC58 {\n        /* margin: 0px 5px; */\n        padding: .375rem 0.5rem !important;\n    }\n}\n\n/* @media (min-wdth: 768px) {\n    .ButtonMargin {\n        margin: 0px 25px;\n    }\n} */", "",{"version":3,"sources":["webpack://src/components/SignInButton/SignInButton.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,oCAAoC;IACpC,gCAAgC;AACpC;;AAEA;IACI;QACI,qBAAqB;QACrB,kCAAkC;IACtC;AACJ;;AAEA;;;;GAIG","sourcesContent":[".FloatRight {\n    float: right;\n}\n\n.ButtonMargin {\n    /* margin: 0px 25px; */\n}\n\n.ButtonColor {\n    background-color: #c7242c !important;\n    border-color: #c7242c !important;\n}\n\n@media only screen and (max-width: 767px) {\n    .ButtonMargin {\n        /* margin: 0px 5px; */\n        padding: .375rem 0.5rem !important;\n    }\n}\n\n/* @media (min-wdth: 768px) {\n    .ButtonMargin {\n        margin: 0px 25px;\n    }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FloatRight": "SignInButton_FloatRight__D2rTW",
	"ButtonMargin": "SignInButton_ButtonMargin__1QC58",
	"ButtonColor": "SignInButton_ButtonColor__1iYuL"
};
export default ___CSS_LOADER_EXPORT___;
