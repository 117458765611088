import React from 'react';
import { ISearchProps } from './ISearchProps';
import { ISearchState } from './ISearchState';
import styles from './Search.module.css';
import { RouteProps } from "react-router-dom";
import { searchByEntityOptions, searchResultCountOptions } from './SearchDataOptions';
import { Button, Dimmer, Dropdown, DropdownProps, Loader, Segment } from 'semantic-ui-react';
import SearchCaller from '../SearchCaller/SearchCaller';
import SearchUtility from '../SearchUtility/SearchUtility';
import SearchStreet from '../SearchStreet/SearchStreet';
import SearchEnquiry from '../SearchEnquiry/SearchEnquiry';
import { SearchForSingleEntity } from '../../services/searchSingleEntityService';
import { CallerEntitySearchResultColumns, EnquiryEntitySearchResultColumns, StreetEntitySearchResultColumns, UtilityEntitySearchResultColumns } from './SearchResultOptions';
import DataTable from 'react-data-table-component';
import SearchExpandableRow from '../SearchExpandableRow/SearchExpandableRow';
import { ExportToExcel } from '../../services/fileDownloadService';

export default class Search extends React.Component<ISearchProps & RouteProps, ISearchState> {

  constructor(props: any){
    super(props);
    this.state = {
      searchEntity: (this.props.location as any)?.state?.searchEntity || 'Caller',
      searchResultCount: 100,
      searchQueryData: {},
      isSearching: false,
      searchResultColumns: [],
      searchResults: [],
      isSearchResultEmpty: true,
      hasSearchBeenUsed: false,
      hasSearchError: false,
      errorMessage: ''
    }
  }

  private handleSearchEntityChanged = async( data: DropdownProps ) => {
    await this.setState( {searchEntity : data.value as string} )
  }

  private handleSearchResultCountChanged = async( data: DropdownProps ) => {
    await this.setState( { searchResultCount : data.value as number} )
  }

  private handleExportToExcel = () => {
    ExportToExcel(this.state.searchResults);
  }

  private searchQueryDataChanged = async( searchQueryDataFromChildren: {} ) => {

    let searchQueryDataWithResultCount = { ...searchQueryDataFromChildren, 'ResultCount': this.state.searchResultCount};
    await this.setState({
      hasSearchError: false,
      searchQueryData: searchQueryDataWithResultCount
    });

    if(!this.state.isSearching)
    {
      //console.log(JSON.stringify(this.state.searchQueryData));
      await this.setState({ isSearching: true });

      try {
        var resultData = await SearchForSingleEntity(this.state.searchQueryData);
      }
      catch(e)
      {
        await this.setState({
          hasSearchError: true,
          errorMessage: (e as Error).message,
        });
      }
   
      if(!Array.isArray(resultData)) {
        if(!this.state.hasSearchError)
        {
          await this.setState({
            hasSearchError: true,
            errorMessage: resultData,
          });
        }
      }
      else {
        var entitySearched = (this.state.searchQueryData as any)["Entity"]

        switch (entitySearched)
        {
          case "Caller":
            await this.setState({ searchResultColumns: CallerEntitySearchResultColumns });
            break;

          case "Utility":
            await this.setState({ searchResultColumns: UtilityEntitySearchResultColumns });
            break;

          case "Street":
            await this.setState({ searchResultColumns: StreetEntitySearchResultColumns });
            break;

          case "Enquiry":
            await this.setState({ searchResultColumns: EnquiryEntitySearchResultColumns });
            break;
        }

        await this.setState({
          searchResults : resultData,
          isSearchResultEmpty : resultData.length > 0 ? false : true,
          errorMessage: ''
        })
      };

      await this.setState({
        isSearching: false,
        hasSearchBeenUsed: true
      });

      //console.log(JSON.stringify(resultData));
    }
  }

  public render(): React.ReactElement<ISearchProps>
  {
    return (
      <div className={ styles.Search }>
        <div className={ styles.Title }>
          <h4>Search</h4>
        </div>

        <div className={ styles.SearchForEntityMainDiv }>
          <div className= { styles.SearchForEntityDropdownDiv }>
            <span className={ styles.SearchForText }>Search for</span>
            <Dropdown className={ styles.DarkerDropdown } options= { searchByEntityOptions } selection  
                value= { this.state.searchEntity } onChange={ (e, data) => {this.handleSearchEntityChanged(data)}} />
          </div>
          <div className= { styles.SearchForEntityDropdownDiv }>
            <span className={ styles.ResultCountText }>Result count</span>
              <Dropdown className={ styles.DarkerDropdown } options= { searchResultCountOptions } selection  
                  value= { this.state.searchResultCount.toString() } onChange={ (e, data) => {this.handleSearchResultCountChanged(data)}} />
          </div>
        </div>

        <div className={ styles.SearchSectionDiv }>
          {
            this.state.searchEntity === 'Caller' &&
            <SearchCaller searchButtonClicked = {this.searchQueryDataChanged}/>
          }
          {
            this.state.searchEntity === 'Utility' &&
            <SearchUtility searchButtonClicked = {this.searchQueryDataChanged}/>
          }
          {
            this.state.searchEntity === 'Street' &&
            <SearchStreet searchButtonClicked = {this.searchQueryDataChanged}/>
          }
          {
            this.state.searchEntity === 'Enquiry' &&
            <SearchEnquiry searchButtonClicked = {this.searchQueryDataChanged}/>
          }
        </div>

        <div>
          {
            this.state.hasSearchBeenUsed
            ?
              <div className={ styles.SearchResultHeader}>
                <div>
                  <h4>Search Results</h4>
                </div>

                {
                  !this.state.isSearching && !this.state.hasSearchError && this.state.searchResults.length > 0
                  ?
                  <div className={ styles.ExportButtonDiv }>
                    <Button color='red' onClick={ this.handleExportToExcel }>
                      Export to Excel
                    </Button>
                  </div>
                  :
                    null
                }
              </div>
            :
              null
          }
          
          <div>
            {
              this.state.isSearching
              ?
                <div className={ styles.SearchLoadingIcon }>
                  <Segment>
                    <Dimmer active inverted>
                      <Loader inverted>Loading</Loader>
                    </Dimmer>
                    <div className={ styles.SearchLoadingHeight }></div>
                  </Segment>
                </div>
              : 
                null
            }
            
            {
              this.state.hasSearchBeenUsed && !this.state.isSearching && !this.state.hasSearchError
              ?
                <div>
                  <DataTable
                    columns = { this.state.searchResultColumns }
                    data = { this.state.searchResults }
                    pagination
                    paginationRowsPerPageOptions = { [5, 10, 25, 50, 100] }
                    paginationTotalRows = { 0 }
                    noDataComponent = { <span className={ styles.SearchResultEmptyText }>No result found.</span> }
                    striped
                    highlightOnHover
                    expandableRows
                    expandOnRowClicked
                    expandableRowsComponent = { SearchExpandableRow }
                    expandableRowsComponentProps = {{'key': (row: any, index: any) => index}}
                  />
                </div>
              : 
                null
            }

            {
              this.state.hasSearchBeenUsed && this.state.hasSearchError && !this.state.isSearching
              ?
                <div className={ styles.ErrorMessage }>
                  { this.state.errorMessage }
                </div>
              : 
                null
            }

          </div>
        </div>
      </div>
    );
  }
}
