import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import styles from './Dashboard.module.css';
import { IDashboardProps } from './IDashboardProps';
import { IDashboardState } from './IDashboardState';


export default class Dashboard extends React.Component<IDashboardProps, IDashboardState> {

  public render(): React.ReactElement<IDashboardProps> 
  {

    const buttonStyles =  ' ' +styles.LargeFont + ' ' + styles.RedButton;

    return (
      <div className={ styles.Dashboard } >
        <div className={ styles.ButtonsDiv }>
          <Link to={{
            pathname: '/search',
            state: {
              searchEntity: "Caller"
            }
          }} >
            <Button size='massive' className={ styles.SearchByButtons + buttonStyles } >Search by Caller</Button>
          </Link>

          <Link to={{
            pathname: '/search',
            state: {
              searchEntity: "Utility"
            }
          }} >
            <Button size='massive' className={ styles.SearchByButtons + buttonStyles } >Search by Utility</Button>
          </Link>

          <Link to={{
            pathname: '/search',
            state: {
              searchEntity: "Street"
            }
          }} >
             <Button size='massive' className={ styles.SearchByButtons + buttonStyles } >Search by Street</Button>
          </Link>

          <Link to={{
            pathname: '/search',
            state: {
              searchEntity: "Enquiry"
            }
          }} >
            <Button size='massive' className={ styles.SearchByButtons + buttonStyles } >Search by Enquiry</Button>
          </Link>
          
          <Link to='/querybuilder'>
            <Button size='massive' className={ buttonStyles }>Advanced Query Builder</Button>
          </Link>

        </div>
      </div>
    );
  }
}
