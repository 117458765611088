// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigationBar_NavPosition__3XM3v {\n    display: block !important;\n    overflow: auto !important;\n}\n\n.NavigationBar_TitlePadding__11F0V {\n    padding: 5px 25px;\n}\n\n.NavigationBar_NavLogo__-iqXS {\n    width: 175px;\n}\n\n.NavigationBar_WelcomeText__2BF45 {\n    color: white;\n    padding-bottom: 3px;\n}\n\n@media only screen and (max-width: 767px) {\n    .NavigationBar_TitlePadding__11F0V {\n        padding: 5px 5px;\n    }\n\n    .NavigationBar_NavLogo__-iqXS {\n        width: 100px;\n    }\n\n    .NavigationBar_navbar-brand__1Qj5a {\n        margin-right: 0.5rem;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/NavigationBar/NavigationBar.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI;QACI,gBAAgB;IACpB;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,oBAAoB;IACxB;AACJ","sourcesContent":[".NavPosition {\n    display: block !important;\n    overflow: auto !important;\n}\n\n.TitlePadding {\n    padding: 5px 25px;\n}\n\n.NavLogo {\n    width: 175px;\n}\n\n.WelcomeText {\n    color: white;\n    padding-bottom: 3px;\n}\n\n@media only screen and (max-width: 767px) {\n    .TitlePadding {\n        padding: 5px 5px;\n    }\n\n    .NavLogo {\n        width: 100px;\n    }\n\n    .navbar-brand {\n        margin-right: 0.5rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NavPosition": "NavigationBar_NavPosition__3XM3v",
	"TitlePadding": "NavigationBar_TitlePadding__11F0V",
	"NavLogo": "NavigationBar_NavLogo__-iqXS",
	"WelcomeText": "NavigationBar_WelcomeText__2BF45",
	"navbar-brand": "NavigationBar_navbar-brand__1Qj5a"
};
export default ___CSS_LOADER_EXPORT___;
