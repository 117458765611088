// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchEnquiry_DarkerDropdown__32yOr {\n    border: 1px solid rgb(34 36 38 / 50%) !important;\n}\n\n.SearchEnquiry_DateInput__22HDo input {\n    padding: .54em 2.1em 0.54em 1em !important;\n}\n\n.SearchEnquiry_DarkerInput__1fZW- {\n    max-width: 33% !important;\n}\n\n.SearchEnquiry_DarkerInput__1fZW- input {\n    border: 1px solid rgb(34 36 38 / 50%) !important;\n}\n\n.SearchEnquiry_DarkerInput__1fZW- input::placeholder {\n    color: rgb(121 121 121 / 87%) !important;\n}\n\n.SearchEnquiry_SearchButton__JZfOu button {\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    padding: .78571429em 3em .78571429em !important;\n}\n\n.SearchEnquiry_MinHeightGroup__2FDXj {\n    min-height: 40px;\n}", "",{"version":3,"sources":["webpack://src/components/SearchEnquiry/SearchEnquiry.module.css"],"names":[],"mappings":"AAAA;IACI,gDAAgD;AACpD;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gDAAgD;AACpD;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,+CAA+C;AACnD;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".DarkerDropdown {\n    border: 1px solid rgb(34 36 38 / 50%) !important;\n}\n\n.DateInput input {\n    padding: .54em 2.1em 0.54em 1em !important;\n}\n\n.DarkerInput {\n    max-width: 33% !important;\n}\n\n.DarkerInput input {\n    border: 1px solid rgb(34 36 38 / 50%) !important;\n}\n\n.DarkerInput input::placeholder {\n    color: rgb(121 121 121 / 87%) !important;\n}\n\n.SearchButton button {\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    padding: .78571429em 3em .78571429em !important;\n}\n\n.MinHeightGroup {\n    min-height: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DarkerDropdown": "SearchEnquiry_DarkerDropdown__32yOr",
	"DateInput": "SearchEnquiry_DateInput__22HDo",
	"DarkerInput": "SearchEnquiry_DarkerInput__1fZW-",
	"SearchButton": "SearchEnquiry_SearchButton__JZfOu",
	"MinHeightGroup": "SearchEnquiry_MinHeightGroup__2FDXj"
};
export default ___CSS_LOADER_EXPORT___;
