import React from 'react';
import { Form, Icon, InputOnChangeData } from 'semantic-ui-react';
import { ISearchCallerProps } from './ISearchCallerProps';
import { ISearchCallerState } from './ISearchCallerState';
import styles from './SearchCaller.module.css';


export default class SearchCaller extends React.Component<ISearchCallerProps, ISearchCallerState> { 

  constructor(props: any){
    super(props);
    this.state = {
      userInputCallerID: '',
      userInputFirstName: '',
      userInputLastName: '',
      userInputEmailAddress: '',
      userInputCompanyName: '',
      userInputDateCreatedFrom: '',
      userInputDateCreatedTo: '',
      queryData: {'Entity' : 'Caller'},
      searchQueryData: {}
    }
  }

  private updateQueryData = async() => {
    let queryDataJson = {
      'Entity': 'Caller',
      'CallerID': this.state.userInputCallerID,
      'FirstName': this.state.userInputFirstName,
      'LastName': this.state.userInputLastName,
      'EmailAddress': this.state.userInputEmailAddress,
      'CompanyName': this.state.userInputCompanyName,
      'DateCreatedFrom': this.state.userInputDateCreatedFrom,
      'DateCreatedTo': this.state.userInputDateCreatedTo
    }
    await this.setState( {queryData: queryDataJson} );
  }

  private handleFormSubmit = async() => {
    await this.setState({ searchQueryData: this.state.queryData });
    this.props.searchButtonClicked( this.state.queryData );
  }

  private handleInputChanges = async (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    let stateName = "userInput" + data.name;
    await this.setState( { [stateName as any] : data.value as string } as Pick<ISearchCallerState, keyof ISearchCallerState> ) ;
    await this.updateQueryData();
  }

  private handleClearDate = async(componentName: string) => {
    let stateName = 'userInput' + componentName;
    await this.setState( { [stateName as any] : '' } as Pick<ISearchCallerState, keyof ISearchCallerState> ) ;
    await this.updateQueryData();
  }

  public render(): React.ReactElement<ISearchCallerProps>
  {
    return (
      <Form onSubmit= { this.handleFormSubmit }>
        <Form.Group widths='equal'>
          <Form.Input fluid type='number' name='CallerID' label='Caller ID' placeholder='Caller ID'
              value= {this.state.userInputCallerID} 
              className={ styles.DarkerInput } onChange={ (e, data) => this.handleInputChanges(e, data)}/>
          <Form.Input fluid name='FirstName' label='First Name' placeholder='First Name'
            value= {this.state.userInputFirstName} 
            className={ styles.DarkerInput } onChange={ (e, data) => this.handleInputChanges(e, data)}/>
          <Form.Input fluid name='LastName' label='Last Name' placeholder='Last Name'
              value= {this.state.userInputLastName} 
            className={ styles.DarkerInput } onChange={ (e, data) => this.handleInputChanges(e, data)}/>
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input fluid name='EmailAddress' label='Email Address' placeholder='Email Address'
              value= {this.state.userInputEmailAddress} 
              className={ styles.DarkerInput } onChange={ (e, data) => this.handleInputChanges(e, data)}/>
          <Form.Input fluid name='DateCreatedFrom' type='date' className={ styles.DarkerInput + " " + styles.DateInput } label='Date Created From' placeholder='Date Created From'
            value= {this.state.userInputDateCreatedFrom} min='1970-01-01'
            onChange={ (e, data) => {this.handleInputChanges(e, data)}} 
            icon={ <Icon link name='close' onClick={ () => {this.handleClearDate('DateCreatedFrom')} } /> } />
          <Form.Input fluid name='DateCreatedTo' type='date' className={ styles.DarkerInput + " " + styles.DateInput } label='Date Created To' placeholder='Date Created To'
            value= {this.state.userInputDateCreatedTo} min='1970-01-01'
            onChange={ (e, data) => {this.handleInputChanges(e, data)}} 
            icon={ <Icon link name='close' onClick={ () => {this.handleClearDate('DateCreatedTo')} } /> } />
        </Form.Group>
        <Form.Group widths='equal' className={ styles.MinHeightGroup }>
          <Form.Input fluid name='CompanyName' label='Company Name' placeholder='Company Name'
            value= {this.state.userInputCompanyName} 
            className={ styles.DarkerInput } onChange={ (e, data) => this.handleInputChanges(e, data)} />
          <Form.Button color='red' className={ styles.SearchButton }>Search</Form.Button>
        </Form.Group>
      </Form>
    );
  }
}