import React from 'react';
import { Form, Icon, InputOnChangeData } from 'semantic-ui-react';
import { ISearchEnquiryProps } from './ISearchEnquiryProps';
import { ISearchEnquiryState } from './ISearchEnquiryState';
import styles from './SearchEnquiry.module.css';


export default class SearchEnquiry extends React.Component<ISearchEnquiryProps, ISearchEnquiryState> { 

  constructor(props: any){
    super(props);
    this.state = {
      userInputCallerID: '',
      userInputJobNumber: '',
      userInputCreatedOnFrom: '',
      userInputCreatedOnTo: '',
      userInputCreatedBy: '',
      queryData: {'Entity' : 'Enquiry'},
      searchQueryData: {}
    }
  }

  private updateQueryData = async() => {
    let queryDataJson = {
      'Entity': 'Enquiry',
      'CallerID': this.state.userInputCallerID,
      'JobNumber': this.state.userInputJobNumber,
      'CreatedOnFrom': this.state.userInputCreatedOnFrom,
      'CreatedOnTo': this.state.userInputCreatedOnTo
      //'CreatedBy': this.state.userInputCreatedBy
    }
    await this.setState( {queryData: queryDataJson} );
  }

  private handleFormSubmit = async() => {
    await this.setState({ searchQueryData: this.state.queryData });
    this.props.searchButtonClicked( this.state.queryData );
  }

  private handleInputChanges = async (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    let stateName = 'userInput' + data.name;
    await this.setState( { [stateName as any] : data.value as string } as Pick<ISearchEnquiryState, keyof ISearchEnquiryState> ) ;
    await this.updateQueryData();
  }

  private handleClearDate = async(componentName: string) => {
    let stateName = 'userInput' + componentName;
    await this.setState( { [stateName as any] : '' } as Pick<ISearchEnquiryState, keyof ISearchEnquiryState> ) ;
    await this.updateQueryData();
  }

  public render(): React.ReactElement<ISearchEnquiryProps>
  {
    return (
      <Form onSubmit= { this.handleFormSubmit }>
        <Form.Group widths='equal'>
          <Form.Input fluid type='number' name='CallerID' label='Caller ID' placeholder='Caller ID'
            value= {this.state.userInputCallerID}
            className={ styles.DarkerInput } onChange={ (e, data) => this.handleInputChanges(e, data)}/>
           <Form.Input fluid name='CreatedOnFrom' type='date' label='Created On From' placeholder='Created On From'
            value= {this.state.userInputCreatedOnFrom} min='1970-01-01'
            className={ styles.DarkerInput + " " + styles.DateInput } onChange={ (e, data) => {this.handleInputChanges(e, data)}} 
            icon={ <Icon link name='close' onClick={ () => {this.handleClearDate('CreatedOnFrom')} } /> } />
          <Form.Input fluid name='CreatedOnTo' type='date' label='Created On To' placeholder='Created On To'
            value= {this.state.userInputCreatedOnTo} min='1970-01-01'
            className={ styles.DarkerInput + " " + styles.DateInput } onChange={ (e, data) => {this.handleInputChanges(e, data)}} 
            icon={ <Icon link name='close' onClick={ () => {this.handleClearDate('CreatedOnTo')} } /> } />
          {/* <Form.Input fluid name='CreatedBy' label='Created By' placeholder='Created By'
            value= {this.state.userInputCreatedBy}
            className={ styles.DarkerInput } onChange={ (e, data) => this.handleInputChanges(e, data)}/> */}
        </Form.Group>
        <Form.Group widths='equal' className={ styles.MinHeightGroup }>
          <Form.Input fluid type='number' name='JobNumber' label='Job Number' placeholder='Job Number'
            value= {this.state.userInputJobNumber}
            className={ styles.DarkerInput } onChange={ (e, data) => this.handleInputChanges(e, data)}/>
          <Form.Button color='red' className={ styles.SearchButton }>Search</Form.Button>
        </Form.Group>
      </Form>
    );
  }
}