import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Dashboard from '../Dashboard/Dashboard';
import { NavigationBar } from '../NavigationBar/NavigationBar';
import QueryBuilder from '../QueryBuilder/QueryBuilder';
import 'semantic-ui-css/semantic.min.css';
import styles from './App.module.css';
import Search from '../Search/Search';

const APPLICATION_TITLE = 'Legacy Data Application';

function App( instance: any ) {

  useEffect(() => {
    document.title = APPLICATION_TITLE
  }, []);

  return (
    <div className='App'>
        <NavigationBar />
        <Container fluid='md'>
            <AuthenticatedTemplate>
              <Router>
                <Switch>
                  <Route exact path='/search' component= {Search} />
                  <Route exact path='/querybuilder' component= {QueryBuilder} />
                  <Route exact path='/' component= {Dashboard} />
                </Switch>
              </Router>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              <div className={ styles.NotSignedIn }>
                <h1>You are not signed in! Please sign in to use search function.</h1>
              </div>
            </UnauthenticatedTemplate>
        </Container>
    </div>
  );
}

export default App;
