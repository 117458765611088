import { SignInButton } from '../SignInButton/SignInButton';
import styles from './NavigationBar.module.css';
import Navbar from 'react-bootstrap/Navbar';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { SignOutButton } from '../SignOutButton/SignOutButton';
import { Container } from 'react-bootstrap';
import logodbyd from '../../assets/logo-dbyd.png';
import { msalInstance } from '../../config/authConfig';

export const NavigationBar = () => {

  const account = msalInstance.getActiveAccount();

  return (
    <Navbar sticky='top' bg='dark' variant='dark' className= { styles.NavPosition }>
      <Container fluid='md'>
      <Navbar.Brand href='/'>
        <img
          alt='logo'
          src={logodbyd}
          className={ styles.NavLogo + ' d-inline-block align-top'}
        />
      </Navbar.Brand>
        {/* <a className= { styles.TitlePadding + ' navbar-brand' } href='/'>Dial Before You Dig</a> */}
          <AuthenticatedTemplate>
          <div>
            <div className={styles.WelcomeText}>Welcome {account?.name}</div>
            <div><SignOutButton /> </div>
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <SignInButton />
        </UnauthenticatedTemplate>
      </Container>
    </Navbar>
  )
}
