import React from 'react';
import styles from './SignOutButton.module.css';
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";
import { IPublicClientApplication } from '@azure/msal-browser';

function handleLogout(instance: IPublicClientApplication) {
    instance.logoutRedirect().catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    var ButtonClasses = styles.FloatRight + " " + styles.ButtonMargin + " " + styles.ButtonColor + " ml-auto FloatRight";

    return (
        <Button variant="secondary" className={ ButtonClasses } onClick={() => handleLogout(instance)}>Sign Out</Button>
    );
}