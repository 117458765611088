
import XLSX from 'xlsx';

export function ExportToExcel(searchResults: any[]) {
  const downloadFileName = GetDownloadFileName();
  const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(searchResults);
  
  var workSheetCols = GetColumnWidth(searchResults);
  workSheet["!cols"] = workSheetCols;

  const workBook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workBook, workSheet);
  XLSX.writeFile(workBook, downloadFileName);
}

function GetColumnWidth(searchResults: any[]) {
  var json = searchResults;
  const jsonKeys = Object.keys(json[0]);

  let objectMaxLength: any[] = [];
  for (let i = 0; i < json.length; i++) {
      let value = json[i];
      for (let j = 0; j < jsonKeys.length; j++) {
        const len = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0;
        objectMaxLength[j] =
            objectMaxLength[j] >= len ?
            objectMaxLength[j] :
            len + 2;
      }
      let key = jsonKeys;
      for (let j = 0; j < key.length; j++) {
          objectMaxLength[j] =
              objectMaxLength[j] >= key[j].length ?
              objectMaxLength[j]:
              key[j].length + 2;
      }
  }

  const wscols = objectMaxLength.map(w => { return { width: w} });
  return wscols;
}

function GetDownloadFileName() {
  let currentDateTime = new Date();
  let dd = currentDateTime.getDate() < 10 ? '0' + currentDateTime.getDate() : currentDateTime.getDate();
  let mm = currentDateTime.getMonth() < 10 ? '0' + currentDateTime.getMonth() : currentDateTime.getMonth();
  let yyyy = currentDateTime.getFullYear();
  let hours = currentDateTime.getHours() < 10 ? '0' + currentDateTime.getHours() : currentDateTime.getHours();
  let minutes = currentDateTime.getMinutes() < 10 ? '0' + currentDateTime.getMinutes() : currentDateTime.getMinutes();
  let seconds = currentDateTime.getSeconds() < 10 ? '0' + currentDateTime.getSeconds() : currentDateTime.getSeconds();

  var fileName = 'LegacyApp_ExportData_' + yyyy + mm + dd + 'T' + hours + minutes + seconds + '.xlsx';

  return fileName;
}