import React from 'react';
import { IQueryBuilderProps } from './IQueryBuilderProps';
import { IQueryBuilderState } from './IQueryBuilderState';
import { Button, Dimmer, Loader, Segment, Table } from 'semantic-ui-react';
import styles from './QueryBuilder.module.css';
import '../../common/semanticstyle.css';
import './QueryBuilderOptions';
import QueryBuilderTableRow  from '../QueryBuilderTableRow/QueryBuilderTableRow';
import { ExportToExcel } from '../../services/fileDownloadService'
import { SearchForAdvanceSearchQueryBuilder } from '../../services/advanceQueryBuilderService';
import { CallerEntitySearchResultColumns, UtilityEntitySearchResultColumns, StreetEntitySearchResultColumns, EnquiryEntitySearchResultColumns } from '../Search/SearchResultOptions';
import DataTable from 'react-data-table-component';
import SearchExpandableRow from '../SearchExpandableRow/SearchExpandableRow';

export default class QueryBuilder extends React.Component<IQueryBuilderProps, IQueryBuilderState>
{
  constructor(props: any){
    super(props);
    this.state = {
      tableRows: [],
      disableSearchButton: true,
      queryDataAll: [],
      isSearching: false,
      searchResultColumns: [],
      searchResults: [],
      isSearchResultEmpty: true,
      hasSearchBeenUsed: false,
      hasSearchError: false,
      errorMessage: ''
    }
  }

  componentDidMount() {
    this.setState( { tableRows : this.state.tableRows?.concat(
      < QueryBuilderTableRow 
        key={ this.state.tableRows.length + 1 } 
        rowNumber = { this.state.tableRows.length + 1 }
        addQueryBuilderRowClicked = { this.handleAddQueryBuilderRow }
        onQueryUpdate = { this.updateAllQueryData }
      />) 
    });
  }

  public returnTotalRows = () => {
    return this.state.tableRows?.length;
  }

  public updateAllQueryData = async (queryData: {}) => {
    var rowPresent = false;
    let queryDataAllFromState = this.state.queryDataAll;
    for(let qData of queryDataAllFromState)
    {
      if(qData.Row === (queryData as any).Row && (queryData as any).Entity === '' ) {
        rowPresent = true;
        let index = queryDataAllFromState.findIndex( d => d.Row === qData.Row);
        queryDataAllFromState.splice(index, 1);
        await this.setState({ queryDataAll: queryDataAllFromState.sort((a,b) => (a.Row < b.Row ? -1 : 1)) });
        break;
      }
      else if(qData.Row === (queryData as any).Row)
      {
        rowPresent = true;
        let index = queryDataAllFromState.findIndex( d => d.Row === qData.Row);
        queryDataAllFromState.splice(index, 1);
        queryDataAllFromState.push(queryData);
        await this.setState({ queryDataAll: queryDataAllFromState.sort((a,b) => (a.Row < b.Row ? -1 : 1)) });
        break;
      }
    }

    if(!rowPresent) {
      await this.setState({ queryDataAll: [...this.state.queryDataAll, queryData] });
    }

    if(this.state.queryDataAll.length > 0) {
      await this.setState({ disableSearchButton: false });
    }
    else {
      await this.setState({ disableSearchButton: true });
    }
  }

  public handleAddQueryBuilderRow = () => {
    this.setState( { tableRows : this.state.tableRows?.concat(
      < QueryBuilderTableRow 
        key={this.state.tableRows.length + 1} 
        rowNumber = { this.state.tableRows.length + 1 }
        addQueryBuilderRowClicked = { this.handleAddQueryBuilderRow }
        onQueryUpdate = { this.updateAllQueryData }
      />)
    });
  }

  private handleExportToExcel = () => {
    ExportToExcel(this.state.searchResults);
  }

  private handleSearchButtonClicked = async() => {

    await this.setState({ isSearching: true });

    try {
      var resultData = await SearchForAdvanceSearchQueryBuilder(this.state.queryDataAll);
    }
    catch(e)
    {
      await this.setState({
        hasSearchError: true,
        errorMessage: (e as Error).message,
      });
    }

    if(!Array.isArray(resultData)) {
      if(!this.state.hasSearchError)
      {
        await this.setState({
          hasSearchError: true,
          errorMessage: resultData,
        });
      }
    }
    else {

      var entitySearched = (this.state.queryDataAll[0] as any)["Entity"] || "Caller";

      switch (entitySearched)
      {
        case "Caller":
          await this.setState({ searchResultColumns: CallerEntitySearchResultColumns });
          break;

        case "Utility":
          await this.setState({ searchResultColumns: UtilityEntitySearchResultColumns });
          break;

        case "Street":
          await this.setState({ searchResultColumns: StreetEntitySearchResultColumns });
          break;

        case "Enquiry":
          await this.setState({ searchResultColumns: EnquiryEntitySearchResultColumns });
          break;
      }

      await this.setState({
        searchResults : resultData,
        isSearchResultEmpty : resultData.length > 0 ? false : true,
        errorMessage: ''
      })
    }

    await this.setState({
      isSearching: false,
      hasSearchBeenUsed: true
    });

  }

  public render(): React.ReactElement<IQueryBuilderProps> 
  {
    return (
      <div className={styles.QueryBuilder}>
        <div className={styles.Title}>
          <h4>Query Builder</h4>
        </div>
        
        <div>
          <div>
            <Table basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1} textAlign='center'>Is Not</Table.HeaderCell>
                  <Table.HeaderCell width={3} textAlign='center'>Entity</Table.HeaderCell>
                  <Table.HeaderCell width={3} textAlign='center'>Property</Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign='center'>Operator</Table.HeaderCell>
                  <Table.HeaderCell width={3} textAlign='center'>Value</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign='center'>Condition</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign='center'>Add/Save</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                { this.state.tableRows }
              </Table.Body>
            </Table>
          </div>

          <div className={styles.SearchButtonDiv}>
            <div className={styles.SearchHintDiv}>
              Please save rows to search
            </div>
            <Button color='red' className={ styles.SearchButton } onClick={ this.handleSearchButtonClicked } disabled={this.state.disableSearchButton}>
              Search
            </Button>
          </div>
        </div>

        <div>
          {
            this.state.hasSearchBeenUsed
            ?
              <div className={ styles.SearchResultHeader}>
                <div>
                  <h4>Search Results</h4>
                </div>

                {
                  !this.state.isSearching && !this.state.hasSearchError && this.state.searchResults.length > 0
                  ?
                  <div className={ styles.ExportButtonDiv }>
                    <Button color='red' onClick={ this.handleExportToExcel }>
                      Export to Excel
                    </Button>
                  </div>
                  :
                    null
                }
              </div>
            :
              null
          }

          <div>
            {
              this.state.isSearching
              ?
                <div className={ styles.SearchLoadingIcon }>
                  <Segment>
                    <Dimmer active inverted>
                      <Loader inverted>Loading</Loader>
                    </Dimmer>
                    <div className={ styles.SearchLoadingHeight }></div>
                  </Segment>
                </div>
              : 
                null
            }

            {
              this.state.hasSearchBeenUsed && !this.state.isSearching && !this.state.hasSearchError
              ?
                <div>
                  <DataTable
                    columns = { this.state.searchResultColumns }
                    data = { this.state.searchResults }
                    pagination
                    paginationRowsPerPageOptions = { [5, 10, 25, 50, 100] }
                    paginationTotalRows = { 0 }
                    noDataComponent = { <span className={ styles.SearchResultEmptyText }>No result found.</span> }
                    striped
                    highlightOnHover
                    expandableRows
                    expandOnRowClicked
                    expandableRowsComponent = { SearchExpandableRow }
                    expandableRowsComponentProps = {{'key': (row: any, index: any) => index}}
                  />
                </div>
              : 
                null
            }

            {
              this.state.hasSearchBeenUsed && this.state.hasSearchError && !this.state.isSearching
              ?
                <div className={ styles.ErrorMessage }>
                  { this.state.errorMessage }
                </div>
              : 
                null
            }

          </div>

        </div>
      </div>
    );
  }
}
