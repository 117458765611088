import { msalInstance, protectedResources } from "../config/authConfig";
import { APIM_Subscription_Key, FunctionAppURL_AdvanceSearchQueryBuilder } from "../config/functionApp";

const requestHeader = new Headers();
requestHeader.append( 'Content-Type', 'application/json');
requestHeader.append( 'Ocp-Apim-Subscription-Key' , APIM_Subscription_Key);

export async function SearchForAdvanceSearchQueryBuilder(searchQueryData: {} ) {
    
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        const errorMessage = 'Error: User not logged in. Please log in to continue.';
        return errorMessage;
    }
    const authRequest = {
        scopes: protectedResources.apiSearch.scopes,
        account: activeAccount || accounts[0]
    };

    const authResult = await msalInstance.acquireTokenSilent(authRequest);

    const bearer = `Bearer ${authResult.accessToken}`;
    requestHeader.delete( 'Authorization' );
    requestHeader.append( 'Authorization', bearer );

    const requestOptions = {
        method: 'POST',
        headers: requestHeader,
        body: JSON.stringify(searchQueryData)
    };

    let performanceStartTime = performance.now();

    var response;

    try
    {
        response= await fetch(FunctionAppURL_AdvanceSearchQueryBuilder, requestOptions);
    }
    catch(error) {
        const errorMessage = 'Error: An error has occured. ' + (error as Error).message;
        return errorMessage;;
    } 

    let performanceEndTime = performance.now();
    let performanceTimeTaken = (performanceEndTime - performanceStartTime)/1000;
    console.log("Request took: " + performanceTimeTaken.toFixed(3) +" second(s)")

    if(!response.ok)
    {
        const errorMessage = 'Error: An error has occured. ' + response.status + ': ' + response.statusText;
        return errorMessage;
    }

    var data = await response.json();
    return data;
}