export const searchByEntityOptions = [
    { key: 'Caller', value: 'Caller', text: 'Caller', },
    { key: 'Utility', value: 'Utility', text: 'Utility' },
    { key: 'Street', value: 'Street', text: 'Street' },
    { key: 'Enquiry', value: 'Enquiry', text: 'Enquiry' }
]

export const searchResultCountOptions = [
    { key: '100', value: '100', text: '100', },
    { key: '500', value: '500', text: '500' },
    { key: '1000', value: '1000', text: '1000' },
    { key: '5000', value: '5000', text: '5000' }
]