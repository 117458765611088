// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignOutButton_FloatRight__36mVX {\n    float: right;\n}\n\n.SignOutButton_ButtonMargin__25WRD {\n    /* margin: 0px 25px; */\n}\n\n.SignOutButton_ButtonColor__1A1w6 {\n    background-color: #c7242c !important;\n    border-color: #c7242c !important;\n}\n\n@media only screen and (max-width: 767px) {\n    .SignOutButton_ButtonMargin__25WRD {\n        /* margin: 0px 5px; */\n        padding: .375rem 0.5rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://src/components/SignOutButton/SignOutButton.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,oCAAoC;IACpC,gCAAgC;AACpC;;AAEA;IACI;QACI,qBAAqB;QACrB,uBAAuB;IAC3B;AACJ","sourcesContent":[".FloatRight {\n    float: right;\n}\n\n.ButtonMargin {\n    /* margin: 0px 25px; */\n}\n\n.ButtonColor {\n    background-color: #c7242c !important;\n    border-color: #c7242c !important;\n}\n\n@media only screen and (max-width: 767px) {\n    .ButtonMargin {\n        /* margin: 0px 5px; */\n        padding: .375rem 0.5rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FloatRight": "SignOutButton_FloatRight__36mVX",
	"ButtonMargin": "SignOutButton_ButtonMargin__25WRD",
	"ButtonColor": "SignOutButton_ButtonColor__1A1w6"
};
export default ___CSS_LOADER_EXPORT___;
