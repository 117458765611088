import React from 'react';
import { Form, InputOnChangeData } from 'semantic-ui-react';
import { ISearchCallerProps } from '../SearchCaller/ISearchCallerProps';
import { ISearchUtilityProps } from './ISearchUtilityProps';
import { ISearchUtilityState } from './ISearchUtilityState';
import styles from './SearchUtility.module.css';


export default class SearchUtility extends React.Component<ISearchUtilityProps, ISearchUtilityState> { 

  constructor(props: any){
    super(props);
    this.state = {
      userInputUtilityID: '',
      userInputUtilityName: '',
      queryData: {'Entity' : 'Utility'},
      searchQueryData: {}
    }
  }

  private updateQueryData = async() => {
    let queryDataJson = {
      'Entity': 'Utility',
      'UtilityID': this.state.userInputUtilityID,
      'UtilityName': this.state.userInputUtilityName
    }
    await this.setState( {queryData: queryDataJson} );
  }

  private handleFormSubmit = async() => {
    await this.setState({ searchQueryData: this.state.queryData });
    this.props.searchButtonClicked( this.state.queryData );
  }

  private handleInputChanges = async (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    let stateName = "userInput" + data.name;
    await this.setState( { [stateName as any] : data.value as string } as Pick<ISearchUtilityState, keyof ISearchUtilityState> ) ;
    await this.updateQueryData();
  }

  public render(): React.ReactElement<ISearchCallerProps>
  {
    return (
      <Form onSubmit= { this.handleFormSubmit }>
        <Form.Group widths='equal'>
          <Form.Input fluid type='number' name='UtilityID' label='Utility ID' placeholder='Utility ID'
              value= {this.state.userInputUtilityID} 
              className={ styles.DarkerInput } onChange={ (e, data) => this.handleInputChanges(e, data)}/>
          <Form.Input fluid name='UtilityName' label='Utility Name' placeholder='Utility Name'
            value= {this.state.userInputUtilityName} 
            className={ styles.DarkerInput } onChange={ (e, data) => this.handleInputChanges(e, data)}/>
            <Form.Button color='red' className={ styles.SearchButton }>Search</Form.Button>
        </Form.Group>
      </Form>
    );
  }
}