import React from 'react';
import { ISearchExpandableRowProps } from './ISearchExpandableRowProps';
import { ISearchExpandableRowState } from './ISearchExpandableRowState';
import styles from './SearchExpandableRow.module.css';


export default class SearchExpandableRow extends React.Component<ISearchExpandableRowProps, ISearchExpandableRowState> {
  uniqueKey: any = 1;

  constructor(props: any){
    super(props);
    this.state = {
      uniqueKey : 0
    }
  }

  private camelCaseToWords = (camelCaseWord: string) => {
    return camelCaseWord.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
  }

  private getAndUpdateKey = () => {
    let currentUniqueKey = this.uniqueKey;
    this.uniqueKey++;
    return currentUniqueKey;
  }

  public render(): React.ReactElement<ISearchExpandableRowProps>
  {
    const rowData = this.props.data;
    const rowPropertiesItems = [];

    for(var prop in rowData)
    {
      if(prop === "CreatedDate")
      {
        continue;
      }
      
      if((rowData as any)[prop] != null && (rowData as any)[prop] !== "" && prop !== 'ObjectWKT')
      {
        rowPropertiesItems.push(
          <div className={ styles.PropertyDiv } key={ this.getAndUpdateKey() }>
            <span className={ styles.PropertyName }>{ this.camelCaseToWords(prop) }</span>:
            <span className={ styles.PropertyValue }>{ (rowData as any)[prop] }</span>
          </div>
        );
      }
    }

    if((rowData as any)['ObjectWKT'] != null)
    {
      rowPropertiesItems.push(
        <div className={ styles.PropertyDivFullWidth } key={ this.getAndUpdateKey() }>
          <span className={ styles.PropertyName }>{ this.camelCaseToWords('ObjectWKT') }</span>:
          <span className={ styles.PropertyValue }>{ (rowData as any)['ObjectWKT'] }</span>
        </div>
      );
    }

    return (
      <div className={ styles.SearchExpandableRow }>
        { rowPropertiesItems }
      </div>
    );
  }
}