export const entityOptions = [
  { key: 'Caller', value: 'Caller', text: 'Caller', },
  { key: 'Enquiry', value: 'Enquiry', text: 'Enquiry' },
  { key: 'EnquiryOutputs', value: 'EnquiryOutputs', text: 'Enquiry Outputs' },
  { key: 'Utility', value: 'Utility', text: 'Utility' },
  { key: 'UtilityOutput', value: 'UtilityOutput', text: 'Utility Output' },
  { key: 'CallerNotifications', value: 'CallerNotifications', text: 'Caller Notifications' },
  { key: 'UtilityNotifications', value: 'UtilityNotifications', text: 'Utiliy Notifications' }
]

export const entityCallerPropertiesOptions = [
  { key: 'Caller_DateCreated', value: 'DateCreated', text: 'Date Created' },
  { key: 'Caller_CompanyName', value: 'CompanyName', text: 'Company Name' },
  { key: 'Caller_FirstName', value: 'FirstName', text: 'First Name' },
  { key: 'Caller_LastName', value: 'LastName', text: 'Last Name' },
  { key: 'Caller_EmailAddress', value: 'EmailAddress', text: 'Email Address' }
]

export const entityEnquiryPropertiesOptions = [
  { key: 'Enquiry_JobNumber', value: 'JobNumber', text: 'Job Number' },
  { key: 'Enquiry_CreatedOn', value: 'CreatedOn', text: 'Created On' },
  { key: 'Enquiry_CreatedBy', value: 'CreatedBy', text: 'Created By' },
  { key: 'Enquiry_DateCreated', value: 'DateCreated', text: 'Date Created' },
  { key: 'Enquiry_EnquiryType', value: 'EnquiryType', text: 'Enquiry Type' },
  { key: 'Enquiry_CommencementDate', value: 'CommencementDate', text: 'Commencement Date' },
  { key: 'Enquiry_LotNo', value: 'LotNo', text: 'Lot No' },
  { key: 'Enquiry_StreetAddress', value: 'StreetAddress', text: 'Street Address' },
  { key: 'Enquiry_Suburb', value: 'Suburb', text: 'Suburb' },
  { key: 'Enquiry_State', value: 'State', text: 'State' },
  { key: 'Enquiry_PostCode', value: 'PostCode', text: 'Post Code' },
  { key: 'Enquiry_WorkType', value: 'WorkType', text: 'Work Type' },
  { key: 'Enquiry_Xlocation', value: 'Xlocation', text: 'Xlocation' },
  { key: 'Enquiry_Ylocation', value: 'Ylocation', text: 'Ylocation' },
  { key: 'Enquiry_LocationInRoad', value: 'LocationInRoad', text: 'Location In Road' },
  { key: 'Enquiry_CompletionDate', value: 'CompletionDate', text: 'Completion Date' },
  { key: 'Enquiry_AuthorityName', value: 'AuthorityName', text: 'Authority Name' }
]

export const entityEnquiryOutputsPropertiesOptions = [
  { key: 'EnquiryOutputs_CreatedOn', value: 'CreatedOn', text: 'Created On' },
  { key: 'EnquiryOutputs_CreatedBy', value: 'CreatedBy', text: 'Created By' }
]

export const entityUtilityPropertiesOptions = [
  { key: 'Utility_UtilityID', value: 'UtilityID', text: 'Utility ID' },
  { key: 'Utility_UtilityName', value: 'UtilityName', text: 'Utility Name' }
]

export const entityUtilityOutputOutputPropertiesOptions = [
  { key: 'UtilityOutput_SequenceNumber', value: 'SequenceNumber', text: 'Sequence Number' },
  { key: 'UtilityOutput_CreatedOn', value: 'CreatedOn', text: 'Created On' },
  { key: 'UtilityOutput_CreatedBy', value: 'CreatedBy', text: 'Created By' }
]

export const entityCallerNotificationsOutputPropertiesOptions = [
  { key: 'CallerNotifications_DateCreated', value: 'DateCreated', text: 'Date Created' },
  { key: 'CallerNotifications_DestinationAddress', value: 'DestinationAddress', text: 'Destination Address' }
]

export const entityUtilityNotificationsOutputPropertiesOptions = [
  { key: 'UtilityNotifications_DateCreated', value: 'DateCreated', text: 'Date Created' },
  { key: 'UtilityNotifications_UtilityID', value: 'UtilityID', text: 'Utility ID' },
  { key: 'UtilityNotifications_DestinationAddress', value: 'DestinationAddress', text: 'Destination Address' }
]

export const userInputStateOptions = [
  { key: 'ACT', value: 'ACT', text: 'ACT' },
  { key: 'NSW', value: 'NSW', text: 'NSW' },
  { key: 'NT', value: 'NT', text: 'NT' },
  { key: 'QLD', value: 'QLD', text: 'QLD' },
  { key: 'SA', value: 'SA', text: 'SA' },
  { key: 'VIC', value: 'VIC', text: 'VIC' },
  { key: 'WA', value: 'WA', text: 'WA' }
]

export const operatorOptions = [
  { key: 'equals', value: 'equals', text: 'Equals'},
  { key: 'contains', value: 'contains', text: 'Contains' },
  { key: 'goe', value: 'goe', text: 'Greater or equals' },
  { key: 'gt', value: 'gt', text: 'Greater than' },
  { key: 'loe', value: 'loe', text: 'Less or equals' },
  { key: 'lt', value: 'lt', text: 'Less than' },
  { key: 'ne', value: 'ne', text: 'Not equals' },
]

export const conditionsOptions = [
  { key: 'and', value: 'and', text: 'And' },
  { key: 'or', value: 'or', text: 'Or' }
]
