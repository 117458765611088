import React from 'react';
import { IQueryBuilderTableRowProps } from './IQueryBuilderTableRowProps';
import { IQueryBuilderTableRowState } from './IQueryBuilderTableRowState';
import styles from './QueryBuilderTableRow.module.css';
import { 
    conditionsOptions, 
    entityCallerNotificationsOutputPropertiesOptions, 
    entityEnquiryPropertiesOptions, 
    entityCallerPropertiesOptions, 
    entityEnquiryOutputsPropertiesOptions, 
    entityOptions, 
    entityUtilityNotificationsOutputPropertiesOptions, 
    entityUtilityOutputOutputPropertiesOptions, 
    entityUtilityPropertiesOptions, 
    operatorOptions, 
    userInputStateOptions} from '../QueryBuilder/QueryBuilderOptions';
import { Button, Checkbox, Dropdown, DropdownProps, Form, Icon, Input, InputOnChangeData, Table, TextArea, TextAreaProps } from 'semantic-ui-react';

export default class QueryBuilderTableRow extends React.Component<IQueryBuilderTableRowProps, IQueryBuilderTableRowState> {

    constructor(props: any){
        super(props);
        this.state = {
            entityPropertiesOptionsDynamic: [],
            userValueFieldType: 'input',
            userInputFieldType: 'text',
            userValueFieldTypeName: '',
            userInputFieldClassName: 'state',
            userInputFieldMax: 'unset' ,
            userInputFieldMin: 'unset' ,
            qbIsNotChecked: false,
            qbEntityValue: '',
            qbPropertyValue: '',
            qbOperatorValue: 'equals',
            qbUserInputValue: '',
            qbConditionValue: 'and',
            shouldRenderAddButton: true,
            disabledAddButton: true,
            disableFields: false,
            editingQBRow: false,
            disabledEditCompleteButton: true,
            queryData: {}
        }
    }

    private handleAddButtonDisabled = async () => {
        if(
            (
                !this.isEmpty(this.state.qbEntityValue) &&
                !this.isEmpty(this.state.qbPropertyValue) &&
                !this.isEmpty(this.state.qbUserInputValue)
            )
            &&
            (
                (
                    (this.state.userInputFieldType === 'number') &&
                    (
                        (
                            this.state.userInputFieldMax === 'unset' &&
                            this.state.userInputFieldMin === 'unset'
                        )
                        ||
                        (
                            !(this.state.userInputFieldMax === 'unset') &&
                            !(this.state.userInputFieldMin === 'unset') &&
                            Number(this.state.qbUserInputValue) >= Number(this.state.userInputFieldMin) &&
                            Number(this.state.qbUserInputValue) <= Number(this.state.userInputFieldMax)
                        )
                    )
                )
                || ( this.state.userValueFieldType === 'dropdown' )
                || ( this.state.userValueFieldType === 'textarea' )
                || ( this.state.userInputFieldType === 'date' )
                || ( this.state.userInputFieldType === 'text' )
            )
        )
        {
            await this.setState({
                disabledAddButton: false,
                disabledEditCompleteButton: false
            });
        }
        else {
            await this.setState({
                disabledAddButton: true,
                disabledEditCompleteButton: true
            });
        }
    }

    private isEmpty(str: string) {
        return (!str || str.length === 0 || !str.trim())
    }

    private disableAllFields = async () => {
        await this.setState({ disableFields: true });
    }

    private enableAllFields = async () => {
        await this.setState({ disableFields: false });
    }

    private handleAddQueryBuilderRowClicked = async() => {
        this.disableAllFields();
        await this.setState( {shouldRenderAddButton: false} );
        await this.updateQueryData();
        this.props.addQueryBuilderRowClicked();
    }

    private handleEditQueryBuilderRowClicked = async() => {
        this.enableAllFields();
        await this.removeQueryData();
        await this.setState( {editingQBRow: true} );
    }

    private handleEditQueryBuilderRowCompleteClicked = async() => {
        this.disableAllFields();
        await this.updateQueryData();
        await this.setState( {editingQBRow: false} );
    }

    private removeQueryData = async() => {
        let queryDataBuilder = {
            'Row': this.props.rowNumber
        };
        await this.setState({ queryData: queryDataBuilder });
        this.props.onQueryUpdate(this.state.queryData);
    }

    private updateQueryData = async() => {
        let queryDataBuilder = {
            'Row': this.props.rowNumber,
            'IsNot': this.state.qbIsNotChecked,
            'Entity': this.state.qbEntityValue,
            'Property': this.state.qbPropertyValue,
            'Operator': this.state.qbOperatorValue,
            'UserInput': this.state.qbUserInputValue,
            'Condition': this.state.qbConditionValue
        };
        await this.setState({ queryData: queryDataBuilder });
        this.props.onQueryUpdate(this.state.queryData);
    }

    private updatePropertyDropdown = async (entityValue: string) => {

        switch(entityValue) {
            case 'Caller':
                await this.setState( {entityPropertiesOptionsDynamic : entityCallerPropertiesOptions} );
                break;
            case 'Enquiry':
                await this.setState( {entityPropertiesOptionsDynamic : entityEnquiryPropertiesOptions} );
                break;
            case 'EnquiryOutputs':
                await this.setState( {entityPropertiesOptionsDynamic : entityEnquiryOutputsPropertiesOptions} );
                break;
            case 'Utility':
                await this.setState( {entityPropertiesOptionsDynamic : entityUtilityPropertiesOptions} );
                break;
            case 'UtilityOutput':
                await this.setState( {entityPropertiesOptionsDynamic : entityUtilityOutputOutputPropertiesOptions} );
                break;
            case 'CallerNotifications':
                await this.setState( {entityPropertiesOptionsDynamic : entityCallerNotificationsOutputPropertiesOptions} );
                break;
            case 'UtilityNotifications':
                await this.setState( {entityPropertiesOptionsDynamic : entityUtilityNotificationsOutputPropertiesOptions} );
                break;
            default:
                await this.setState( {entityPropertiesOptionsDynamic : []} );
                break;
        }
    }

    private updateValueInputField = async(propertyValue: string) => {
        switch(propertyValue) {
            case 'DateCreated':
            case 'CreatedOn':
            case 'CommencementDate':
            case 'CompletionDate':
                await this.setState({
                    userValueFieldType : 'input',
                    userInputFieldType : 'date',
                    userInputFieldClassName : styles.DateInput,
                    userInputFieldMin : '1970-01-01',
                    userInputFieldMax : 'unset',
                    qbUserInputValue : '',
                    userValueFieldTypeName : ''
                });
                break;
            
            case 'JobNumber':
            case 'LotNo':
            case 'Number':
            case 'UtilityID':
            case 'SequenceNumber':
                await this.setState({
                    userValueFieldType : 'input',
                    userInputFieldType : 'number',
                    userInputFieldClassName : '',
                    userInputFieldMin : 'unset',
                    userInputFieldMax : 'unset',
                    qbUserInputValue : '',
                    userValueFieldTypeName : ''

                });
                break;

            case 'PostCode':
                await this.setState({
                    userValueFieldType : 'input',
                    userInputFieldType : 'number',
                    userInputFieldClassName : '',
                    userInputFieldMin : 1000,
                    userInputFieldMax : 9999,
                    qbUserInputValue : '',
                    userValueFieldTypeName : ''

                });
                break;

            case 'State':
                await this.setState({
                    userValueFieldType : 'dropdown',
                    userValueFieldTypeName : 'state'
                });
                break;
            
            case 'DestinationAddress':
            case 'LocationInRoad':
                await this.setState( {userValueFieldType : 'textarea'} );
                break;

            default:
                await this.setState({
                    userValueFieldType : 'input',
                    userInputFieldType : 'text',
                    userInputFieldClassName : '',
                    userInputFieldMin : 'unset',
                    userInputFieldMax : 'unset',
                    qbUserInputValue : '',
                    userValueFieldTypeName : ''
                });
                break;
        }
    }

    private handleQBIsNotChanged = async(rowNumber: number) => {
        await this.setState( {qbIsNotChecked : !this.state.qbIsNotChecked} )
    }

    private handleQBEntityChanged = async(rowNumber: number, data: DropdownProps ) => {
        await this.setState( {qbEntityValue : data.value as string} )
        await this.handleQBPropertyChanged(rowNumber, data);
        await this.updatePropertyDropdown((data.value as string));
        await this.handleAddButtonDisabled();
    }

    private handleQBPropertyChanged = async(rowNumber: number, data: DropdownProps ) => {
        await this.setState( {qbPropertyValue : data.value as string} )
        await this.updateValueInputField((data.value as string));
        await this.handleAddButtonDisabled();
    }

    private handleQBOperatorChanged = async(rowNumber: number, data: DropdownProps ) => {
        await this.setState( {qbOperatorValue : data.value as string} )
    }

    private handleQBUserInputChanged = async(rowNumber: number, data: InputOnChangeData ) => {
        await this.setState( {qbUserInputValue : data.value as string} )
        await this.handleAddButtonDisabled();
    }

    private handleQBUserInputChangedDropdown = async(rowNumber: number, data: DropdownProps ) => {
        await this.setState( {qbUserInputValue : data.value as string} )
        await this.handleAddButtonDisabled();
    }

    private handleQBUserInputChangedTextArea = async(rowNumber: number, data: TextAreaProps ) => {
        await this.setState( {qbUserInputValue : data.value as string} )
        await this.handleAddButtonDisabled();
    }

    private handleQBConditionChanged = async(rowNumber: number, data: DropdownProps ) => {
        await this.setState( {qbConditionValue : data.value as string} )
    }

    private handleClearDate = async() => {
        await this.setState({ qbUserInputValue: '' });
        await this.handleAddButtonDisabled();
    }


    public render(): React.ReactElement<IQueryBuilderTableRowProps> 
    {

        return(
            <Table.Row id={'row' + this.props.rowNumber}>
                <Table.Cell verticalAlign='middle' textAlign='center'>
                    <Checkbox className={ styles.LargeCheckbox }
                        onChange={(e) => {this.handleQBIsNotChanged(this.props.rowNumber)} } checked={this.state.qbIsNotChecked} 
                        disabled={this.state.disableFields} />
                </Table.Cell>
                <Table.Cell>
                    <Dropdown className={ styles.DarkerDropdown } clearable placeholder='Select Entity' options={ entityOptions } selection
                        value= {this.state.qbEntityValue} onChange={ (e, data) => {this.handleQBEntityChanged(this.props.rowNumber, data)}} 
                        disabled={this.state.disableFields} />
                </Table.Cell>
                <Table.Cell>
                    <Dropdown className={ styles.DarkerDropdown } clearable placeholder='Select Property' options={ this.state.entityPropertiesOptionsDynamic } selection 
                        value= {this.state.qbPropertyValue} onChange={ (e, data) => {this.handleQBPropertyChanged(this.props.rowNumber, data)}}
                        disabled={this.state.disableFields} />
                </Table.Cell>
                <Table.Cell>
                    <Dropdown className={ styles.DarkerDropdown } options= { operatorOptions } selection  
                        value= {this.state.qbOperatorValue} onChange={ (e, data) => {this.handleQBOperatorChanged(this.props.rowNumber, data)}}
                        disabled={this.state.disableFields} />
                </Table.Cell>
                <Table.Cell>
                    {
                        this.state.userValueFieldType === 'input' &&
                        <Input className={ styles.DarkerInput + ' ' + this.state.userInputFieldClassName } placeholder='Enter Value' 
                            value= {this.state.qbUserInputValue} onChange={ (e, data) => {this.handleQBUserInputChanged(this.props.rowNumber, data)}}
                            disabled={this.state.disableFields} type={ this.state.userInputFieldType } min={ this.state.userInputFieldMin } max={ this.state.userInputFieldMax } 
                            {...this.state.userInputFieldType === 'date' && {icon: <Icon link name='close' onClick={ this.handleClearDate } /> } } />
                    }
                    {
                        this.state.userValueFieldType === 'dropdown' && this.state.userValueFieldTypeName === 'state' &&
                        <Dropdown className={ styles.DarkerDropdown } clearable placeholder='Select State' options= { userInputStateOptions } selection 
                            value= {this.state.qbUserInputValue} onChange={ (e, data) => {this.handleQBUserInputChangedDropdown(this.props.rowNumber, data)}}
                            disabled={this.state.disableFields} />
                    }
                    {
                        this.state.userValueFieldType === 'textarea' &&
                        <Form>
                            <TextArea className={ styles.DarkerTextarea } placeholder='Enter Value' 
                            value= {this.state.qbUserInputValue} onChange={ (e, data) => {this.handleQBUserInputChangedTextArea(this.props.rowNumber, data)}}
                            disabled={this.state.disableFields} />
                        </Form>
                    }
                </Table.Cell>
                <Table.Cell>
                    <Dropdown className={ styles.DarkerDropdown } options= { conditionsOptions } selection 
                        value= {this.state.qbConditionValue} onChange={ (e, data) => {this.handleQBConditionChanged(this.props.rowNumber, data)}}
                        disabled={this.state.disableFields} />
                </Table.Cell>
                <Table.Cell textAlign='center'>
                    {
                        this.state.shouldRenderAddButton ?
                            <Button icon secondary onClick={ this.handleAddQueryBuilderRowClicked } disabled={ this.state.disabledAddButton } >
                                <Icon name='plus' />
                            </Button>
                        :
                            this.state.editingQBRow ? 
                                <Button icon secondary onClick={ this.handleEditQueryBuilderRowCompleteClicked } disabled={this.state.disabledEditCompleteButton}>
                                    <Icon name='check' />
                                </Button>
                            :
                                <Button icon secondary onClick={ this.handleEditQueryBuilderRowClicked } >
                                    <Icon name='edit' />
                                </Button>
                    }
                </Table.Cell>
            </Table.Row>
        );
    }

}